import React from 'react';
import { M60UspModule } from '~/lib/data-contract';
import { Image, Text } from '~/shared/components';
import { useModuleTheme } from '../../hooks/useModuleTheme';
import {
    StyledGrid,
    StyledImage,
    StyledItem,
    StyledModuleContainer,
    StyledHeadline,
    StyledUSPText,
} from './styled';

export type M60USPProps = M60UspModule;

export const M60USP = ({ items, ...rest }: M60USPProps) => {
    const { textColor } = useModuleTheme(rest);

    return (
        <StyledModuleContainer hasGutter {...rest}>
            <StyledGrid count={items.length}>
                {items.map(({ headline, text, image, imageAltText }, index) => (
                    <StyledItem textColor={textColor} key={index}>
                        {image && (
                            <StyledImage>
                                <Image {...image} alt={imageAltText} sizes="60px" />
                            </StyledImage>
                        )}
                        <StyledUSPText column>
                            {headline && (
                                <StyledHeadline variant="body" as="p">
                                    {headline}
                                </StyledHeadline>
                            )}
                            {text && <Text variant="body" children={text} />}
                        </StyledUSPText>
                    </StyledItem>
                ))}
            </StyledGrid>
        </StyledModuleContainer>
    );
};
