import styled from '@emotion/styled';
import { StyledText, Text } from '~/shared/components';
import Flex from '~/shared/components/Flex';
import { breakpoints } from '~/theme/breakpoints';
import { ModuleContainer } from '../ModuleContainer';

type StyledGridProps = {
    count: number;
};

type StyledItemProps = {
    textColor: string;
};

export const StyledModuleContainer = styled(ModuleContainer)(({ theme }) => ({
    background: theme.colors.grey10,
    borderTop: `1px solid ${theme.colors.headerSearchInputBorderColor}`,
    boxShadow: 'inset 0px 30px 30px -25px rgba(0,0,0,0.2)',
}));

export const StyledGrid = styled.ul<StyledGridProps>(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: theme.space[5],
    [breakpoints.md]: {
        gap: theme.space[7],
    },
}));
export const StyledItem = styled.li<StyledItemProps>(({ theme, textColor }) => ({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    gap: theme.space['3'],
    [breakpoints.md]: {
        textAlign: 'left',
        gap: theme.space['4'],
        flexDirection: 'row',
        justifyContent: 'start',
    },
    img: {
        display: 'block',
        maxWidth: '100%',
        height: 'auto',
    },
    [`${StyledText}`]: {
        color: textColor,
    },
}));

export const StyledImage = styled.div({
    width: 60,
});

export const StyledHeadline = styled(Text)({
    fontWeight: '500',
});

export const StyledUSPText = styled(Flex)({
    alignItems: 'center',
});
